<template>
    <div v-if="role">
        <el-form :model="role" :rules="rules" ref="ruleForm" label-width="100px" style="max-width: 350px;">
            <el-form-item label="职务名称" prop="name">
                <el-input disabled v-model="role.name"></el-input>
            </el-form-item>
            <el-form-item label="职务描述" prop="desc">
                <el-input disabled type="textarea" :rows="2" v-model="role.desc"></el-input>
            </el-form-item>
            <hr>
            <template v-if="pivis">
                <el-form-item v-for="(group,index) of pivis" :label="group.name" :key="index">
                    <el-checkbox-group v-model="role.privs">
                        <el-checkbox disabled v-for="(pivi,_index) of group.privs"  :label="pivi.id" :key="_index">{{pivi.name}}
                        </el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
            </template>
            <el-form-item>
                <el-button type="primary" @click="save">保存</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
    import { getCanAssignPrivs,addRole,setRole } from '@/service/purview';
    export default {
        props: ['form','popTitle'],
        data() {
            return {
                role:{
                    name:null,
                    privs:[]
                },
                pivis: [],
            }
        },
        watch:{
            form:{
                handler() {
                    // this.ruleForm = this.form;
                },
                deep: true
            }
        },
        created() {
            // this.ruleForm = this.form;
            // console.log(this.ruleForm);
            this.refreshItems();
        },
        mounted() {},
        methods: {
            refreshItems() {
                console.log('refreshItems');
                getCanAssignPrivs().then(rst => {
                    if (rst && rst.length>0) {
                        this.selectList = rst;
                    }
                    console.log(this.selectList);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            submitForm() {
                this.$refs['formCheck'].validate((valid) => {
                    if (valid) {
                        this.submit();
                    }
                });
            },
            submit() {
                this.ruleForm.privs = this.checkList;
                this.$emit("hide");
                this.$message.success("新增日志成功");
            },
        }
    }
</script>
<style scoped>
    .list {
        padding: 0 20px;
    }
    .list>div {
        line-height: 35px;
        margin:10px 0;
    }
    .list>div>div:first-child {
        width: 115px;
        text-align: right;
        color: #000;
        margin-right: 15px;
    }
    
</style>