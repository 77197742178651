<template>
    <div style="padding:0 20px;">
        <div class="list">
            <div class="flex">
                <div>姓名</div>
                <div>{{ruleForm.name}}</div>
            </div>
            <div class="flex">
                <div>性别</div>
                <div>
                    <span v-if="ruleForm.gender==1">男</span>
                    <span v-if="ruleForm.gender==2">女</span>
                </div>
            </div>
            <div class="flex">
                <div>头像</div>
                <div class="credit-img">
                    <img v-if="ruleForm.avatar" :src="imgUrl+ruleForm.avatar" style="width:100%;height:100%">
                </div>
            </div>
            <div class="flex">
                <div>个人介绍</div>
                <div>{{ruleForm.intro}}</div>
            </div>
            <div class="flex">
                <div>手机</div>
                <div>{{ruleForm.phone}}</div>
            </div>
            <div class="flex">
                <div>座机</div>
                <div>{{ruleForm.tel}}</div>
            </div>
            <div class="flex">
                <div>邮箱</div>
                <div>{{ruleForm.mail}}</div>
            </div>
            <div class="flex">
                <div>微信</div>
                <div>{{ruleForm.wechat}}</div>
            </div>
            <div class="flex">
                <div>QQ</div>
                <div>{{ruleForm.qq}}</div>
            </div>
        </div>
    </div>
</template>
<script>
    import { getCanAssignPrivs,addRole,setRole } from '@/service/purview';
    export default {
        props: ['form','popTitle'],
        data() {
            return {
                rules: {
                    name: [
                        { required: true, message: '请输入权限分类名称', trigger: 'blur' }
                    ],
                },
                ruleForm:{
                    name:"前进",
                    desc:"",
                    time:"2020-1-12",
                },
                checkList:[],
                selectList:[],
            }
        },
        watch:{
            form:{
                handler() {
                    // this.ruleForm = this.form;
                },
                deep: true
            }
        },
        created() {
            // this.ruleForm = this.form;
            // console.log(this.ruleForm);
            this.refreshItems();
        },
        mounted() {},
        methods: {
            refreshItems() {
                console.log('refreshItems');
                getCanAssignPrivs().then(rst => {
                    if (rst && rst.length>0) {
                        this.selectList = rst;
                    }
                    console.log(this.selectList);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            submitForm() {
                this.$refs['formCheck'].validate((valid) => {
                    if (valid) {
                        this.submit();
                    }
                });
            },
            submit() {
                this.ruleForm.privs = this.checkList;
                this.$emit("hide");
                this.$message.success("新增日志成功");
            },
        }
    }
</script>
<style scoped>
    .list {
        padding: 0 20px;
    }
    .list>div {
        line-height: 35px;
        margin:10px 0;
    }
    .list>div>div:first-child {
        width: 115px;
        text-align: right;
        color: #000;
        margin-right: 15px;
    }
    .credit-img{
        width:145px;
        height:145px;
        border:1px dashed rgba(204,204,204,1);
        overflow: hidden;
        display: inline-block;
    }
    
</style>