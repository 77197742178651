<template>
    <div style="height:100%">
        <div style="font-size: 16px;font-weight:600;" class="page-header">
            <i @click="back()" style="cursor:pointer;margin-right:4px;" class="iconfont icon-Chevron"></i>
            <span @click="back()" style="cursor:pointer;margin-right:4px;color: #409EFF;">返回列表</span>
            <i style="margin-right:4px;" class="iconfont icon-Chevron"></i>
            <span>员工和权限查看</span>
        </div>
        <div style="margin:20px;" class="flex">
            <div style="padding:4px 0;" class="flex-1">
                <el-button @click="goto('/purview/invite')" size="small" type="primary">发送邀请码</el-button>
            </div>
            <div>
                <el-input style="max-width:250px;" placeholder="搜索客户"
                prefix-icon="el-icon-search" v-model="inputValue"></el-input>
            </div>
        </div>
        <div class="block" style="height: calc(100% - 166px)">
            <div class="list" style="height: calc(100% - 60px);overflow-y: auto">
                <el-table :data="list" style="width: 100%">
                    <el-table-column prop="privs" label="客户名称" min-width="150">
                        <template slot-scope="scope">
                            <div>{{scope.row.name}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="注册状态">
                        <template slot-scope="scope">
                            <div v-if="scope.row.stat == 0">邀请中</div>
                            <div v-if="scope.row.stat == 1">已注册</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="time" label="上次登录" min-width="105">
                        <template slot-scope="scope">
                            <div>{{scope.row.time}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="position" label="职务">
                        <template slot-scope="scope">
                            <div>{{scope.row.position}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="dept" label="部门">
                        <template slot-scope="scope">
                            <div>{{scope.row.dept}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="address" label="操作" width="150">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="lookPerson(scope.row)">
                                员工资料
                            </el-button>
                            <el-button type="text" size="small" @click="lookDept(scope.row)">
                                职务/部门/权限
                            </el-button>
                            <el-button type="text" size="small" @click="del(scope.row)">
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div style="padding:12px">
                <el-pagination background layout="prev, pager, next" :current-page.sync="queryOpt.pageNumber" :page-size="queryOpt.pageCount" :total="total"></el-pagination>
            </div>
        </div>

        <el-dialog :title="popTitle" :visible.sync="popVisible" :destroy-on-close="true">
            <ItemPerson v-if="popTitle=='员工资料'" :form="ruleForm" :popTitle="popTitle" @refreshItems="refreshItems" @hide="popVisible=false"></ItemPerson>
            <ItemDept v-if="popTitle=='职务/部门/权限'" :form="ruleForm" :popTitle="popTitle" @refreshItems="refreshItems" @hide="popVisible=false"></ItemDept>
        </el-dialog>
        
    </div>
</template>
<script>
    import { getRoles,delRol } from '@/service/purview';
    import ItemPerson from './ItemPerson.vue';
    import ItemDept from './ItemDept.vue';
    export default {
        components: {
            ItemPerson,ItemDept
        },
        data() {
            return {
                popVisible: false,
                popTitle: '',
                queryOpt:{
                    pageNumber:1,
                    pageCount:15,
                },
                total:0,
                list:[],
                inputValue:"",
                ruleForm:"",
            }
        },
        mounted() {
            let obj = {
                id:"111",
                name:"李四",
                position:"厂长",
                dept:"总经办",
                stat:0,
                time:"2020/1/12",
            };
            this.list.push(obj);
            this.list.push(obj);
            this.list.push(obj);
            this.list.push(obj);
            this.list.push(obj);
            this.list.push(obj);
            this.list.push(obj);
            this.list.push(obj);
        },
        created() {
            this.refreshItems();
        },
        methods: {
            goto(link) {
                this.$router.push(link)
            },
            back() {
                this.$router.go(-1)
            },
            // 选项卡
            tab(index) {
                this.tabIndex = index;
            },
            refreshItems() {
                console.log('refreshItems');
                getRoles().then(rst => {
                    // if (rst && rst.length>0) {
                    //     this.list = rst;
                    // }
                    console.log(this.list);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            lookPerson() {
                this.popTitle = "员工资料";
                this.popVisible = true;
                this.ruleForm = {
                    privs:"",
                };
            },
            lookDept() {
                this.popTitle = "职务/部门/权限";
                this.popVisible = true;
                this.ruleForm = {
                    privs:"",
                };
            },
            del(item) {
                this.$confirm(`确认要删除吗?`, '温馨提示', {
                    confirmButtonText: '确定',cancelButtonText: '取消',type: 'warning'
                }).then(() => {
                    this.$message.success('删除成功!');
                });
            }
        }
    }
</script>
<style scoped src="../style.css"></style>
<style scoped>
    div>>> .el-dialog {
        width: 600px;
    }
</style>